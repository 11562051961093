<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" name="form" :model="modelRef" @finish="onSubmit" :labelCol="{ span: 4 }" :wrapperCol="{span: 16}">
        <h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">评价邀请配置</h4>
        <a-form-item label="是否启用" name="isAfterShow">
          <a-switch v-model:checked="modelRef.isAfterShow" checked-children="启用" un-checked-children="禁用" />
        </a-form-item>

        <a-form-item label="影片放映结束后" name="afterShow">
          <a-input
            v-model:value="modelRef.afterShow"
            style="width: 60px; border-bottom-left-radius: 10px; border-top-left-radius: 10px"
          ></a-input>
          <span class="ui-min">分钟</span>
          <span style="margin-left: 5px">发送</span>
        </a-form-item>

        <h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">订单生效配置</h4>
        <a-form-item label="是否启用" name="isAfterOrder">
          <a-switch v-model:checked="modelRef.isAfterOrder" checked-children="启用" un-checked-children="禁用" />
        </a-form-item>

        <a-form-item label="订单生效后" name="afterOrder">
          <a-input
            v-model:value="modelRef.afterOrder"
            style="width: 60px; border-bottom-left-radius: 10px; border-top-left-radius: 10px"
          ></a-input>
          <span class="ui-min">分钟</span>
          <span style="margin-left: 5px">发送</span>
        </a-form-item>

        <h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">订单过期配置</h4>
        <a-form-item label="是否启用" name="isBeforeOrder">
          <a-switch v-model:checked="modelRef.isBeforeOrder" checked-children="启用" un-checked-children="禁用" />
        </a-form-item>

        <a-form-item label="订单过期前" name="beforeOrder">
          <a-input
            v-model:value="modelRef.beforeOrder"
            style="width: 60px; border-bottom-left-radius: 10px; border-top-left-radius: 10px"
          ></a-input>
          <span class="ui-min">分钟</span>
          <span style="margin-left: 5px">发送</span>
        </a-form-item>



        <a-row>
          <a-col :offset="6" style="margin-top: 40px;">
            <a-button v-permission="['operation_message_configuration_submit']" type="primary" html-type="submit">提交</a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      modelRef: {
        isAfterShow: false,
        isAfterOrder: false,
        isBeforeOrder: false,
      }
    }
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {

    },
    async onSubmit() {

    },
  }
}
</script>

<style scoped>
.ui-min {
  display: inline-block;
  width: 40px;
  height: 31px;
  text-align: center;
  line-height: 31px;
  background-color: #d7d7d7;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
